import React, { useMemo } from 'react'
import { MobileSearchBar } from './MobileSearchBar'
import Grid from '@material-ui/core/Grid'
import { StyledSearchIcon, StyledSearchIconContainer } from './StyledSearchBarIcon'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { useRouter } from 'next/router'

export type SearchBarProps = React.ComponentProps<typeof Grid> & {
  onChange?: (event: React.FormEvent) => void
  onToggle: () => void
  onClear: () => void
  focus: boolean
  inputRef: React.MutableRefObject<HTMLInputElement>
  sticky?: boolean
  secondary?: boolean
}

export const MobileSearchBarContainer: React.FC<SearchBarProps> = ( props ) => {
  const [openSearch, setOpen] = React.useState(false)
  const router = useRouter()
  const isOpen = useMemo(() => {
    if (router?.route === '/search') {
      return true
    }

    return openSearch
  }, [openSearch, router])

  const setOpenSearch = () => {
    if (openSearch) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }

  const unfocus = () => {
    setOpen(false)
  }

  return(
    <React.Fragment>
      {router?.route === '/search' ? null:
      <StyledSearchIconContainer onClick={setOpenSearch}>
        <StyledSearchIcon icon={isOpen ? faTimes : faSearch}  />
      </StyledSearchIconContainer>
      }
      {isOpen &&
        <MobileSearchBar {...props} isOpen={isOpen} unfocus={unfocus} />
      }
    </React.Fragment>
  )
}